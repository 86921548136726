// extracted by mini-css-extract-plugin
export var background = "slides-module--background--pCs6a";
export var background_black = "slides-module--background_black--uZSdK";
export var background_photo = "slides-module--background_photo--n0r55";
export var background_white = "slides-module--background_white--+s7Y-";
export var button = "slides-module--button--Mkx9F";
export var buttons = "slides-module--buttons--NsnMB";
export var next_button = "slides-module--next_button--T+rHi";
export var prev_button = "slides-module--prev_button--z2hL9";
export var slide_wrapper = "slides-module--slide_wrapper--f7LYH";
export var slides_wrapper = "slides-module--slides_wrapper--LN-qd";
export var zoomed = "slides-module--zoomed--5NHV1";